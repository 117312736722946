@import '~antd/dist/antd.css';
@font-face {
  font-family: BrandonText;
  src: url("./fonts/BrandonText-Bold.otf") format("opentype");
}
@font-face {
  font-family: BrandonTextRegular;
  src: url("./fonts/BrandonText-Regular.otf") format("opentype");
}
h1 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.03em;
  color: black;
}
h2 {
  font-family: BrandonText;
  font-weight: 700;
  font-size: 24px;
  font-weight: 200;
  text-align: center;
}
h3 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.03em;
  color: black;
}
.divider {
  width: 100%;
}
html body {
  font-size: 16px;
  font-weight: 200;
  width: auto;
  height: auto;
  min-width: 960px;
}
.section-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  min-width: 960px;
}
.padded {
  padding: 0 50px 0 50px;
}
.logo-name {
  font-family: 'Work Sans';
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 12px;
}
.logo-container {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  flex-direction: row;
  color: white;
}
.logo {
  height: 90%;
}
.nav {
  background-color: #6457FF;
  display: flex;
  flex-direction: row;
  max-height: 74px;
  position: sticky;
  top: 0;
  z-index: 2;
  padding-left: 80px;
  padding-right: 80px;
}
.nav .right-links {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
  font-family: BrandonText;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.nav .right-links .item {
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 20px 4px 20px;
}
.nav .right-links .item a {
  color: white;
  text-decoration: none;
}
.nav .launch {
  background: #FFFFFF;
  box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.25), 2px 2px 10px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
}
.nav .launch a {
  /* Gradient / Cobalt Purple */
  background: linear-gradient(90deg, #2037FF 0%, #6658FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.splash {
  padding-top: 20px;
  background: linear-gradient(180deg, #6457FF 51.89%, rgba(97, 91, 255, 0.263306) 87.58%, rgba(102, 100, 255, 0) 100%);
  padding-bottom: 28px;
  display: flex;
}
.splash .background {
  border-radius: 30px;
  display: flex;
  background: #4a01ce;
  box-shadow: 0px -4px 15px rgba(58, 0, 202, 0.5), 2px 4px 25px rgba(58, 0, 202, 0.5);
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2vh;
  height: 60vh;
  min-width: 960px;
}
@media screen and (min-width: 1440px) {
  .splash .background {
    height: 75vh;
    padding-top: 2vh;
    padding-bottom: 0;
  }
}
.splash .video-container {
  margin-left: auto;
}
.splash video {
  border-radius: 30px;
  clip-path: inset(10px 10px);
  height: 100%;
}
@media screen and (min-width: 1440px) {
  .splash video {
    height: 90%;
  }
}
.splash .title-section {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  letter-spacing: -0.02em;
  margin-right: auto;
  padding-top: 17vh;
  width: 35%;
}
@media screen and (min-width: 1440px) {
  .splash .title-section {
    padding-top: 18vh;
  }
}
.splash .title-section h1 {
  line-height: 40px;
  font-size: 48px;
  color: white;
}
@media screen and (min-width: 1440px) {
  .splash .title-section h1 {
    font-size: 70px;
    line-height: 50px;
  }
}
.splash .title-section h2 {
  font-family: 'Work Sans';
  color: #46C6E6;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  font-size: 28px;
  min-width: 400px;
}
@media screen and (min-width: 1400px) {
  .splash .title-section h2 {
    font-size: 36px;
  }
}
.splash .title-section #first-layer,
.splash .title-section #second-layer {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
}
.splash .title-section #second-layer {
  color: rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  opacity: 0.5;
}
.intro {
  background: white;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.intro .section-container {
  padding-left: 90px;
}
.intro .cta-container {
  margin-left: auto;
  width: 55%;
  display: flex;
  z-index: 1;
}
.intro .cta-container .cta {
  margin-top: auto;
  margin-bottom: auto;
}
.intro .asset {
  width: 45%;
  margin-left: -5%;
  display: flex;
}
.intro .button-container {
  margin-top: 36px;
}
.intro .divider {
  padding-top: 20px;
}
.intro video {
  width: 100%;
  min-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.intro h1 {
  margin-bottom: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
}
.intro p {
  margin-top: 20px;
  font-family: BrandonTextRegular;
  font-style: normal;
  font-weight: 390;
  font-size: 20px;
  line-height: 32px;
}
.strategies {
  display: flex;
  background: #24098F;
  flex-direction: column;
  padding-top: 36px;
}
.strategies h3 {
  text-align: center;
  margin-bottom: 0;
  color: white;
}
.strategies .section-container {
  flex-direction: column;
}
.strategies .strategy-overview {
  display: flex;
  flex-direction: row;
  margin: 40px;
  margin-top: 60px;
  width: 100%;
  max-width: 800px;
}
.strategies .strategy-overview .strategy {
  display: flex;
  flex-direction: column;
  background: #2B008D;
  border: 1px solid rgba(101, 87, 255, 0.3);
  box-shadow: 0px 4px 30px 3px rgba(149, 172, 255, 0.2);
  border-radius: 30px;
  padding-bottom: 30px;
  width: 360px;
  height: 440px;
}
.strategies .strategy-overview .strategy:hover {
  cursor: pointer;
}
.strategies .strategy-overview .strategy.back {
  background: rgba(255, 255, 255, 0.9);
  mix-blend-mode: screen;
  backdrop-filter: blur(50px);
  border-radius: 30px;
  height: 100%;
}
.strategies .strategy-overview .strategy.back .card-container {
  padding: 40px;
}
.strategies .strategy-overview .strategy.back h3 {
  color: #6658FF;
  padding-top: 20px;
}
.strategies .strategy-overview .strategy.back p {
  background-color: inherit;
  margin-top: 40px;
  color: #24098F;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
}
.strategies .strategy-overview .strategy.back .button {
  margin: auto;
  border-radius: 20px;
}
.strategies .strategy-overview .strategy.back .padded {
  padding-top: 30px;
}
.strategies .strategy-overview .strategy h3 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  font-size: 24px;
}
.strategies .strategy-overview .react-card-flip + .react-card-flip {
  margin-left: 5%;
}
.strategies .asset-container {
  display: flex;
  padding: 70px 0 20px 0;
}
.strategies .asset-container img {
  margin: auto;
  height: 240px;
  width: 210px;
}
.strategies img + img {
  margin-left: 20px;
}
.start-earning {
  background: white;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.start-earning .carousel {
  width: 290px;
  margin-left: 80px;
  margin-right: 24px;
}
.start-earning h3 {
  padding-right: 80px;
}
.start-earning .cta-container {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  margin: auto;
}
.start-earning .asset2 {
  max-width: 40%;
}
.start-earning .divider {
  margin: 0;
  margin-top: 60px;
}
.start-earning .section-container + .section-container {
  margin-top: 120px;
}
button {
  all: unset;
}
.button-container {
  display: flex;
  margin-top: 20px;
}
.button-container .button {
  display: flex;
  border: none;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  font-weight: 500;
  padding: 8px 24px;
  height: auto;
  background: linear-gradient(90deg, #2037FF 0%, #6658FF 100%);
  font-family: BrandonText;
}
.team {
  background: #24098F;
  color: white;
  padding-bottom: 34px;
  padding-top: 60px;
  padding-bottom: 50px;
}
.team body {
  color: inherit;
  background: inherit;
  font-size: 18px;
  font-weight: 200;
  padding-right: 60px;
}
.team h1 {
  color: inherit;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: -0.03em;
}
.team img {
  height: 500px;
}
.team p {
  font-family: BrandonTextRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}
.team .section-container {
  display: flex;
  flex-direction: row;
}
.team .container {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
}
.questions {
  margin-bottom: 100px;
}
.questions .divider {
  margin-bottom: 50px;
}
.questions .section-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.questions h2 {
  font-family: 'Work Sans';
  font-weight: 700;
  width: 40%;
  font-size: 30px;
}
.questions .collapse {
  width: 70%;
  flex-grow: 1;
}
.questions .collapse .ant-collapse-item {
  background-color: white;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
}
.questions .collapse .ant-collapse-item p {
  font-family: BrandonTextRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
.questions .collapse .ant-collapse-header {
  flex-direction: row-reverse;
  justify-content: space-between;
  color: #3706FA;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}
.footer {
  display: flex;
  flex-direction: column;
  height: 140px;
  background: #24098F;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.footer .logo-container {
  padding: 0 50px 0 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.footer .logo {
  height: 40px;
}
.footer .copyright {
  color: white;
  padding: 20px 50px 0 50px;
  font-size: 14px;
}
.footer .item {
  color: white;
  font-size: 14px;
  font-family: BrandonTextRegular;
  margin-top: auto;
  margin-bottom: auto;
}
.footer .row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.footer .item + .item {
  margin-left: 50px;
}
.footer .socials {
  margin-left: auto;
  margin-right: 50px;
  display: flex;
}
.footer .socials .item {
  margin-top: auto;
  margin-bottom: auto;
}
.footer .socials .item + .item {
  margin-left: 50px;
}
.ant-modal-content {
  border-radius: 12px;
}
.ant-modal-header {
  padding-top: 24px;
  border-radius: 12px 12px 0 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-modal-title {
  font-size: 18px;
}
.ant-modal-body {
  font-size: 16px;
}
